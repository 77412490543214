import React, { useContext, useState } from "react";
import Header from "../../Component/Patient/Header";
import HeroImg from "../../images/patient/hero.jpeg";
import HeroBlur from "../../images/patient/heroBlur.png";
import { TfiArrowCircleDown } from "react-icons/tfi";
import Feature1 from "../../images/patient/feature3.png";
import Feature2 from "../../images/patient/feature2.png";
import Feature3 from "../../images/patient/feature1.png";
import DemoImg from "../../images/patient/demo.png";
import Drawer from "../../images/patient/desk-drawer.png";
import Internet from "../../images/patient/wifi.png";
import Mug from "../../images/patient/mug.png";
import { FaCheck } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import LatestUpdate from "../../Component/Patient/LatestUpdate";
import Footer from "../../Component/Patient/Footer";
import GlobalStorage from "../../Storage/ContextProvider";
import Switch from "react-switch";
import Card1 from "../../images/patient/card1.png";
import Card2 from "../../images/patient/card2.png";
import Card3 from "../../images/patient/card3.png";
import AddHospitalPopup from "../../Component/Patient/AddHospitalPopup";
import EnterprisePopup from "../../Component/Patient/EnterprisePopup";
import RequestDemo from "../../Component/Patient/RequestDemo";

const PatientPricing = () => {
  const [activeFaq, setActiveFaq] = useState(0);
  const [checked, setChecked] = useState(false);
  const [yearly, setYearly] = useState(false);
  const [addPaymentlActive, setAddPaymentlActive] = useState(false);
  const [enterpriseActive, setEnterpriseActive] = useState(false);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const { isRequestDemo, setRequestDemo } = useContext(GlobalStorage);

  const {
    isOpen,
    setIsOpen,
    planType,
    setPlanType,
    planDuration,
    setPlanDuration,
  } = useContext(GlobalStorage);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    setYearly(nextChecked);
  };
  const handleOverlayClick = () => {
    setAddPaymentlActive(false);
  };

  const handlePopupClick = (e) => {
    e.stopPropagation(); // Prevent closing the popup when clicked inside
  };
  const handleFreePlan = () => {
    setAddPaymentlActive(true);
    setPlanDuration(1);
    setPlanType("Free");
  };
  const handleProviderPlan = () => {
    setAddPaymentlActive(true);
    setPlanType("Provider Pro");
    if (yearly) {
      setPlanDuration(12);
    } else {
      setPlanDuration(1);
    }
  };

  return (
    <>
      <Header />
      {!isOpen && (
        <div className="relative">
          <div
            className={`${
              addPaymentlActive || enterpriseActive || isRequestDemo
                ? "h-[90vh] overflow-hidden opacity-25"
                : ""
            } `}
          >
            <div className="500px:px-[50px] px-[20px] bg-[#CCE1DD] py-[100px] text-[#052721]  font-inter">
              <p className="text-center text-[40px] font-[600]">
                Simple, transparent pricing
              </p>
              <div className="flex items-center justify-center">
                {/* <p className="text-center text-[#6F6C90] text-[18px] w-[650px]">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit dolor
                  posuere vel venenatis eu sit massa volutpat.
                </p> */}
              </div>

              <div className="flex items-center justify-center mt-10 text-[#052721] text-[18px] pb-[70px] mb-11">
                <p>Monthly</p>
                <label className="mx-4">
                  <Switch
                    onChange={handleChange}
                    checked={checked}
                    className="reactToggleCss"
                    handleDiameter={28}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor="#016855"
                    onColor="#DCA071"
                    offHandleColor="#fff"
                    onHandleColor="#fff"
                    height={30}
                    width={60}
                    activeBoxShadow="0px 0px 1px 1px #01483B"
                  />
                </label>
                <p>
                  Annually <span className="text-[#67A599]">(Save 10%)</span>
                </p>
              </div>

              <div className="flex gap-7 1200px:flex-row flex-col justify-center mt-14">
                <div className=" bg-[#fff] text-[#052721] py-[30px] 500px:px-[40px] px-[25px] rounded-[24px] h-[570px] min-w-[310px] ">
                  <div>
                    <img src={Card1} alt="" className="w-[160px] h-[60px]" />
                  </div>
                  <p className="text-[#6F6C90] text-[16px] mt-[10px]">
                    30-day trial{" "}
                  </p>
                  <p className="text-[50px] font-[600] font-inter mt-[35px]">
                    FREE
                  </p>
                  <div>
                    <p className="text-[14px] font-[600]">What's included</p>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Simplification - up to 50 notes
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Translation - up to 50 notes
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">Email support</p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">Video tutorials</p>
                    </div>
                  </div>
                  <button
                    className="bg-[var(--doctor-color)] w-full mt-[30px] rounded-full text-white py-[15px]"
                    onClick={handleFreePlan}
                  >
                    Get Started
                  </button>
                </div>
                <div className=" bg-[var(--doctor-color)] text-[#fff] py-[30px] 500px:px-[40px] px-[20px] rounded-[24px] 500px:h-[580px] 1200px:-mt-[30px]">
                  <div>
                    <img src={Card2} alt="" className="w-[160px] h-[55px]" />
                  </div>
                  <p className="text-[#D9DBE9] text-[16px] mt-[10px] w-[270px]">
                    Best for providers’ use at point of care
                  </p>
                  {yearly ? (
                    <p className="text-[50px] font-[600] font-inter mt-[35px]">
                      $540{" "}
                      <span className="text-[18px] font-[400] text-[#D9DBE9]">
                        account/yearly
                      </span>
                    </p>
                  ) : (
                    <p className="text-[50px] font-[600] font-inter mt-[35px]">
                      $50{" "}
                      <span className="text-[18px] font-[400] text-[#D9DBE9]">
                        account/monthly
                      </span>
                    </p>
                  )}

                  <div>
                    <p className="text-[14px] font-[600]">What's included</p>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px] text-[#D9DBE9]">
                        Unlimited simplification
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px] text-[#D9DBE9]">
                        Unlimited translation
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px] text-[#D9DBE9]">
                        10 free patient portal access
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px] text-[#D9DBE9]">
                        Onboarding support
                      </p>
                    </div>
                  </div>
                  <button
                    className="text-[var(--doctor-color)] w-full mt-[30px] rounded-full bg-white py-[15px] font-[600]"
                    onClick={handleProviderPlan}
                  >
                    Get Started
                  </button>
                </div>
                <div className=" bg-[#fff] text-[#052721] py-[30px] 500px:px-[40px] px-[25px] rounded-[24px] 500px:h-[570px] ">
                  <div>
                    <img src={Card3} alt="" className="w-[160px] h-[55px]" />
                  </div>
                  <p className="text-[#6F6C90] text-[16px] mt-[10px] w-[300px]">
                    For health organizations with multiple departments/providers
                  </p>
                  <p className="text-[40px] font-[600] font-inter mt-[35px]">
                    Custom pricing
                  </p>
                  <div>
                    <p className="text-[14px] font-[600]">What's included</p>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Multiple departments/providers
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Unlimited patient-portal access
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Dedicated support and onboarding
                      </p>
                    </div>
                    <div className="flex items-center gap-3 mt-5">
                      <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                        <FaCheck />
                      </div>
                      <p className="text-[16px]">
                        Ongoing 1:1 training and upgrades
                      </p>
                    </div>
                  </div>
                  <button
                    className="bg-[var(--doctor-color)] w-full mt-[30px] rounded-full text-white py-[15px]"
                    onClick={() => setEnterpriseActive(true)}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="bg-[var(--doctor-color)] 900px:px-[100px] 1300px:px-[200px] py-[100px] px-[30px] font-inter text-white ">
              <div className="w-full flex  900px:flex-row flex-col 900px:gap-5 gap-10">
                <div className="900px:w-[50%] w-full">
                  <div>
                    <h2 className="font-[700] 600px:text-[48px] text-[35px] leading-tight">
                      We are always here for your backup.
                    </h2>
                    <p className="mt-[15px] opacity-65">
                      We share common trends and strategies for creating &
                      improving your rental income.
                    </p>
                    <div className="flex items-start gap-3 mt-[20px]">
                      <div className="w-[30px] h-[30px] rounded-full bg-[#68D585] flex items-center justify-center mt-1 ">
                        <FaCheck className="text-white" size={20} />
                      </div>
                      <div>
                        <h2 className="font-[700] text-[21px]">
                          Noice Free Locations
                        </h2>
                        <p className="mt-[12px] opacity-65 text-[17px]">
                          With lots of unique blocks, you can easily build a
                          page without coding.{" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 mt-[40px]">
                    <div className="w-[30px] h-[30px] rounded-full bg-[#68D585] flex items-center justify-center mt-1 ">
                      <FaCheck className="text-white" size={20} />
                    </div>
                    <div>
                      <h2 className="font-[700] text-[21px]">
                        24/7 Hour Support{" "}
                      </h2>
                      <p className="mt-[12px] opacity-65 text-[17px]">
                        With lots of unique blocks, you can easily build a page
                        without coding.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="900px:w-[50%] w-full">
                  <div className="bg-white rounded-md p-6">
                    <div className="text-[var(--doctor-color)] border-b pt-4 pb-4">
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setActiveFaq(0)}
                      >
                        <h2 className="text-[var(--doctor-color)] font-[700] 600px:text-[21px] text-[18px]">
                          How do I setup my portal?{" "}
                        </h2>
                        {activeFaq === 0 ? (
                          <IoIosArrowUp
                            className="text-[var(--doctor-color)] "
                            size={21}
                          />
                        ) : (
                          <IoIosArrowUp
                            className="text-[var(--doctor-color)] rotate-90"
                            size={21}
                          />
                        )}
                      </div>
                      {activeFaq === 0 && (
                        <p className="opacity-70 mt-[15px]">
                          With lots of unique blocks, you can easily build a
                          page with coding. Build your next landing page.
                          Integer ut obe ryn. Sed feugiat vitae turpis a porta.
                        </p>
                      )}
                    </div>

                    <div className="text-[var(--doctor-color)] border-b pt-4 pb-4">
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setActiveFaq(1)}
                      >
                        <h2 className="text-[var(--doctor-color)] font-[700] 600px:text-[21px] text-[18px] w-[80%]">
                          Can I use translate to any language on the portal?
                        </h2>
                        {activeFaq === 1 ? (
                          <IoIosArrowUp
                            className="text-[var(--doctor-color)] "
                            size={21}
                          />
                        ) : (
                          <IoIosArrowUp
                            className="text-[var(--doctor-color)] rotate-90"
                            size={21}
                          />
                        )}
                      </div>
                      {activeFaq === 1 && (
                        <p className="opacity-70 mt-[15px]">
                          With lots of unique blocks, you can easily build a
                          page with coding. Build your next landing page.
                          Integer ut obe ryn. Sed feugiat vitae turpis a porta.
                        </p>
                      )}
                    </div>

                    <div className="text-[var(--doctor-color)] border-b pt-4 pb-4">
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setActiveFaq(2)}
                      >
                        <h2 className="text-[var(--doctor-color)] font-[700] 600px:text-[21px] text-[18px] w-[80%]">
                          How often do you release update?
                        </h2>
                        {activeFaq === 2 ? (
                          <IoIosArrowUp
                            className="text-[var(--doctor-color)] "
                            size={21}
                          />
                        ) : (
                          <IoIosArrowUp
                            className="text-[var(--doctor-color)] rotate-90"
                            size={21}
                          />
                        )}
                      </div>
                      {activeFaq === 2 && (
                        <p className="opacity-70 mt-[15px]">
                          With lots of unique blocks, you can easily build a
                          page with coding. Build your next landing page.
                          Integer ut obe ryn. Sed feugiat vitae turpis a porta.
                        </p>
                      )}
                    </div>

                    <div className="text-[var(--doctor-color)] border-b pt-4 pb-4">
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setActiveFaq(3)}
                      >
                        <h2 className="text-[var(--doctor-color)] font-[700] 600px:text-[21px] text-[18px] w-[80%]">
                          How can I access to old version?
                        </h2>
                        {activeFaq === 3 ? (
                          <IoIosArrowUp
                            className="text-[var(--doctor-color)] "
                            size={21}
                          />
                        ) : (
                          <IoIosArrowUp
                            className="text-[var(--doctor-color)] rotate-90"
                            size={21}
                          />
                        )}
                      </div>
                      {activeFaq === 3 && (
                        <p className="opacity-70 mt-[15px]">
                          With lots of unique blocks, you can easily build a
                          page with coding. Build your next landing page.
                          Integer ut obe ryn. Sed feugiat vitae turpis a porta.
                        </p>
                      )}
                    </div>

                    <div className="flex justify-end mt-6 ">
                      <button className="bg-[#FFCFAA] text-[var(--doctor-color)] w-[130px] h-[50px] font-[600] rounded-md">
                        View All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white 900px:px-[100px] px-[50px] 1300px:px-[200px] py-[100px] flex 900px:flex-row flex-col font-inter text-[#161C2D] items-center gap-5 ">
              <img
                src={DemoImg}
                className="900px:w-[60%]  1300px:w-[100%] h-auto mb-5"
                alt=""
              />
              <div>
                <h3 className=" 600px:text-[48px] text-[35px] font-[600] leading-tight -mb-[8px] ">
                  Get your medical information{" "}
                </h3>
                <span className="text-[var(--doctor-color)] 600px:text-[48px] text-[35px] font-[600] ">
                  On The Go
                </span>
                <p className="mt-[15px]">
                  WIth Medinclude, you can have access to your medical records
                  without visiting the hospital
                </p>
              </div>
            </div> */}

            <LatestUpdate />
            <Footer />
          </div>
          {addPaymentlActive && (
            <>
              <div onClick={handleOverlayClick} className="w-full">
                <div
                  className="absolute top-5  w-[60%]  bg-[#fff] left-[18%] rounded-[12px] p-5 font-inter"
                  onClick={handlePopupClick}
                >
                  <AddHospitalPopup
                    setAddPaymentlActive={setAddPaymentlActive}
                    
                  />
                </div>
              </div>
            </>
          )}

          {enterpriseActive && (
            <>
              <div className="w-full">
                <div className="absolute top-5  w-[60%]  bg-[#fff] left-[18%] rounded-[12px] p-5 font-inter">
                  {/* <AddHospitalPopup
                    setAddPaymentlActive={setAddPaymentlActive}
                  /> */}
                  <EnterprisePopup setEnterpriseActive={setEnterpriseActive} />
                </div>
              </div>
            </>
          )}

          {isRequestDemo && (
            <>
              <div className="w-full">
                <div className="absolute top-[5%]  500px:w-[60%] w-[90%]  bg-[#fff] 500px:left-[18%] left-[20px] right-[40px] rounded-[12px] p-5 font-inter">
                  <RequestDemo />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PatientPricing;
