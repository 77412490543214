// const BASE_URL = "https://whale-app-rqcs6.ondigitalocean.app/api";
// const BASE_URL = "http://localhost:5000/api";
// const BASE_URL = "https://starfish-app-zdwgl.ondigitalocean.app/api";


const BASE_URL = process.env.REACT_APP_API_URL
// const BASE_URL ="https://api.medinclude.com/api/"
//const BASE_URL = 'https://testapi.medinclude.com/api'

export default BASE_URL;

