import React, { useContext } from "react";
import { BsCurrencyDollar } from "react-icons/bs";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { isAuth } from "../../../auth/helper";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { toast } from "react-toastify";
import Card1 from "../../../images/patient/card1.png";
import Card2 from "../../../images/patient/card2.png";
import Card3 from "../../../images/patient/card3.png";
import { FaCheck } from "react-icons/fa6";
import GlobalStorage from "../../../Storage/ContextProvider";

const Card = ({ togglePlan }) => {
  const { hospitalProfile, setHospitalProfile } = useContext(GlobalStorage);

  const handlePayment = async () => {
    const STRIPE_PUBLISHABLE =
      "pk_live_51P9EUyP6Tq3NahvosaGAuOrJZawJ3A3wS6dm4KRIsEv7hEzINHP6Q6XEAEzN4WzeO9hnAqVutgwfisnFidI6s7Lp0061qBrJue";
    const stripe = await loadStripe(
      process.env.REACT_APP_STRIPE_KEY
      //"pk_test_51P9EUyP6Tq3NahvoptGkxm2J9GY1jBx2pD4J7r4rmrN86qN4k0j3z6owrFMumFkpSwp1WvUGCpFkp1C8xwuUTTCo00JuqBb0Wd"
    );
    const data = {
      hospitalId: isAuth()?._id,
      planType: "basic",
      amount: 50,
      noOfMonths: 3,
    };
    const handleStripe = await axios({
      method: "POST",
      url: `${BASE_URL}/create-checkout-session`,
      data: data,
    })
      .then((response) => {
        console.log("Payment Redirect", response?.data?.id);
        const result = stripe.redirectToCheckout({
          sessionId: response.data.id,
        });
        // toast.success(response?.data?.message);

        // navigate("/hospital/password-success");
        // setDataUser(response.data.user);
      })
      .catch((error) => {
        console.log("Payment Redirect Error  Error", error.response);
        toast.error(error.response.data);
      });
  };
  return (
    <>
      {/* <div className="w-[240px] h-[382px] border-[1px] font-inter border-[#E6EDFF] flex-col rounded-[15px] bg-[var(--dark-primary)] px-[15px] flex items-center justify-center">
        <h2 className="text-[28px] font-inter font-[500]">Starter</h2>
        <p className="text-center text-[16px] mt-[15px]">
          Starter pack to help you to get started
        </p>
        <p className="text-[40px] mt-[50px]">FREE</p>
        <button className="w-[135px] h-[40px] border-[1px] mt-[35px] border-[#fff] rounded-[8px]">
          Get Started
        </button>
      </div> */}

      <div className="flex gap-7 justify-center mt-14">
        <div className={`${hospitalProfile?.subscriptionType === "Provider Pro" ? "opacity-40": ""} bg-[#fff] text-[#052721] py-[30px] px-[40px] rounded-[24px] h-[570px]`}>
          <div>
            <img src={Card1} alt="" className="w-[160px] h-[60px]" />
          </div>
          <p className="text-[#6F6C90] text-[16px] mt-[10px]">30-day trial </p>
          <p className="text-[50px] font-[600] font-inter mt-[35px]">FREE</p>
          <div>
            <p className="text-[14px] font-[600]">What's included</p>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                <FaCheck />
              </div>
              <p className="text-[16px]">Simplification - up to 50 notes</p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                <FaCheck />
              </div>
              <p className="text-[16px]">Translation - up to 50 notes</p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                <FaCheck />
              </div>
              <p className="text-[16px]">Email support</p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                <FaCheck />
              </div>
              <p className="text-[16px]">Video tutorials</p>
            </div>
          </div>
          {hospitalProfile?.subscriptionType === "Free" ? (
            <>
              <button
                className="bg-[var(--doctor-color)] w-full mt-[30px] rounded-full text-white py-[15px]"
                // onClick={handleFreePlan}
              >
                Current Plan
              </button>
            </>
          ) : (
            <button
              className="bg-[var(--doctor-color)] w-full mt-[30px] rounded-full text-white py-[15px]"
              // onClick={handleFreePlan}
            >
              Get Started
            </button>
          )}
        </div>
        <div className=" bg-[var(--doctor-color)] text-[#fff] py-[30px] px-[40px] rounded-[24px] h-[570px] ">
          <div>
            <img src={Card2} alt="" className="w-[160px] h-[55px]" />
          </div>
          <p className="text-[#D9DBE9] text-[16px] mt-[10px] w-[270px]">
            Best for providers’ use at point of care
          </p>
          {togglePlan == 1 ? (
            <p className="text-[50px] font-[600] font-inter mt-[35px]">
              $540{" "}
              <span className="text-[18px] font-[400] text-[#D9DBE9]">
                account/yearly
              </span>
            </p>
          ) : (
            <p className="text-[50px] font-[600] font-inter mt-[35px]">
              $50{" "}
              <span className="text-[18px] font-[400] text-[#D9DBE9]">
                account/monthly
              </span>
            </p>
          )}

          <div>
            <p className="text-[14px] font-[600]">What's included</p>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                <FaCheck />
              </div>
              <p className="text-[16px] text-[#D9DBE9]">
                Unlimited simplification
              </p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                <FaCheck />
              </div>
              <p className="text-[16px] text-[#D9DBE9]">
                Unlimited translation
              </p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                <FaCheck />
              </div>
              <p className="text-[16px] text-[#D9DBE9]">
                10 free patient portal access
              </p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#fff] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[var(--doctor-color)]">
                <FaCheck />
              </div>
              <p className="text-[16px] text-[#D9DBE9]">Onboarding support</p>
            </div>
          </div>
          {hospitalProfile?.subscriptionType === "Provider Pro" ? (
            <button
              className="text-[var(--doctor-color)] w-full mt-[30px] rounded-full bg-white py-[15px] font-[600]"
              // onClick={handleProviderPlan}
            >
              Current Plan
            </button>
          ) : (
            <button
              className="text-[var(--doctor-color)] w-full mt-[30px] rounded-full bg-white py-[15px] font-[600]"
              // onClick={handleProviderPlan}
            >
              Get Started
            </button>
          )}
        </div>
        <div className=" bg-[#fff] text-[#052721] py-[30px] px-[40px] rounded-[24px] h-[570px] ">
          <div>
            <img src={Card3} alt="" className="w-[160px] h-[55px]" />
          </div>
          <p className="text-[#6F6C90] text-[16px] mt-[10px] w-[300px]">
            For health organizations with multiple departments/providers
          </p>
          <p className="text-[40px] font-[600] font-inter mt-[35px]">
            Custom pricing
          </p>
          <div>
            <p className="text-[14px] font-[600]">What's included</p>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                <FaCheck />
              </div>
              <p className="text-[16px]">Multiple departments/providers</p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                <FaCheck />
              </div>
              <p className="text-[16px]">Unlimited patient-portal access</p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                <FaCheck />
              </div>
              <p className="text-[16px]">Dedicated support and onboarding</p>
            </div>
            <div className="flex items-center gap-3 mt-5">
              <div className="bg-[#016855] w-[25px] h-[25px] flex items-center justify-center rounded-full text-[#fff]">
                <FaCheck />
              </div>
              <p className="text-[16px]">Ongoing 1:1 training and upgrades</p>
            </div>
          </div>
          <button className="bg-[var(--doctor-color)] w-full mt-[30px] rounded-full text-white py-[15px]">
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default Card;
